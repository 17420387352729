import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

import{ init } from 'emailjs-com';
import emailjs from 'emailjs-com';

import './plugins'



Vue.config.productionTip = false

init("user_AhewuO3jsV07lJ84t510Y");
Vue.use(emailjs);

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
